html {
  background-color: #FFF9F2;
  color: #212529;

  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

::-moz-selection {
  background: #e9ecef;
  text-shadow: none;
}

::selection {
  background: #e9ecef;
  text-shadow: none;
}

body {
  opacity: 0;
  transition: 320ms opacity;
  overflow-x: hidden;
  padding: 0px 20px;
}

.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

* {
   color: #212529;
   font-family: Helvetica, Arial, sans-serif;
   font: 18px;
   font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  margin: 0px;
}

h1 {
  font-size: 47px;
  margin-top: 40px;
}

h2 {
  font-size: 29px;
}

p {
  margin: 0px;
  font-size: 18px;
}

b {
  font-weight: bold;
}

.button {
  padding: 0px 12px;
  height: 30px;
  line-height: 34px !important;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}

.button.view_collection, .button.buy {
  background-color: #74B816;
  border-radius: 6px;
  border: none;
  -webkit-box-shadow: inset 0px 0px 0px 1px #5C940D;
  -moz-box-shadow: inset 0px 0px 0px 1px #5C940D;
  box-shadow: inset 0px 0px 0px 1px #5C940D;
  float: right;
  cursor: pointer;
}

.button.sold {
  background-color: #e03131;
  border-radius: 6px;
  border: none;
  -webkit-box-shadow: inset 0px 0px 0px 1px #c92a2a;
  -moz-box-shadow: inset 0px 0px 0px 1px #c92a2a;
  box-shadow: inset 0px 0px 0px 1px #c92a2a;
}

.strikethrough {
  text-decoration: line-through;
}

@media screen and (max-width: 600px) {
  body {
    padding: 0px 20px;
  }

  h1 {
    font-size: 29px;
    margin-top: 24px;
  }

  h2 {
    font-weight: 400;
  }
}

.medium-zoom-overlay {
  z-index: 1000;
}

.medium-zoom-image--opened {
  z-index: 1001;
}

p.collection_price_start {
  float: right;
  line-height: 56px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  padding-right: 8px;
}

/* Navigation */
.topnav {
  position: fixed;
  top: 0px;
  overflow: hidden;
  width: 100%;
  height: 56px;
  left: 0px;
  transition: 320ms opacity;
  z-index: 999;
  -webkit-box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  background-color: #FFFCF9;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 56px;
}

.topnav a:first-child {
  float: left;
}

.topnav a:not(:first-child) {
  margin-left: 24px;
}

.topnav a:not(:first-child).active, .topnav a:not(:first-child):hover {
  text-decoration: underline;
}

.topnav .button {
  float: right;
  margin-top: 12px;
  margin-left: 0px;
  background-color: #74B816;
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 0px 1px #5C940D;
  -moz-box-shadow: 0px 0px 0px 1px #5C940D;
  box-shadow: 0px 0px 0px 1px #5C940D;
  font-size: 14px;
  font-weight: 600;
}

.topnav .icon {
  display: none;
}

.topnav .inner_container {
  display: inline-block;
  width: 100%;
  height: 55px;
}

.topnav .inner_padding_container {
  padding: 0px 20px;
  -webkit-box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 1px 0px 0px #E6DCCF, 0px 0px 8px 0px rgba(0,0,0,0.08);
  background-color: #FFFCF9;
  text-align: center;
}

@media screen and (max-width: 840px) {
  .topnav {
    width: auto;
    left: 0px;
    right: 0px;
    transform: none;
  }
}

@media screen and (max-width: 600px) {
  .topnav {
    display: block;
    opacity: 1;
    left: 0px;
    right: 0px;
  }

  .topnav .menu_item {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    height: auto;
    overflow: visible;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 20px;
    top: 0;
  }

  .topnav.responsive a, .topnav.responsive .menu_item {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .menu_item.collection_container {
    display: inline-block;
  }

  .topnav.responsive a.menu_item {
    margin-left: 0px;
    padding-top: 4px;
    line-height: 38px;
    border-bottom: 1px solid #E6DCCF;
    text-align: center;
  }

  .topnav.responsive a:first-child {
    border-bottom: 1px solid #E6DCCF;
  }

  .topnav .button.buy {
    display: none;
  }
}

/* Main content */
.main {
  width: 100%;
  max-width: 800px;
  padding-top: 56px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 0px;
  }
}

.description_container {
  display: inline-block;
}

.description_container > p {
  padding: 0px 12%;
}

.image_container {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.image_border {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.06);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.06);
  box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.06);
  pointer-events: none;
}

.image_container img {
  width: auto;
  float: left;
}

#hero {
  min-width: 100%;
  min-height: 100vh;
  text-align: center;
  margin: 0 auto;
}

#hero .description_container {
  position: fixed;
  width: 500px;
  top: calc(56px + 8vh);
  left: 46%;
  -webkit-transition: opacity 0.32s ease-in-out;
  -moz-transition: opacity 0.32s ease-in-out;
  transition: opacity 0.32s ease-in-out;
}

#hero .description_container > p {
  padding: 0px;
}

#hero .description_container.invisible {
  opacity: 0;
}

#hero .description_container h1 {
  line-height: 50px;
}

#hero .description_container h2 {
  line-height: 40px;
}

#hero .description_container button {
  margin-top: 12px;
}

#hero .collection {
  display: inline-block;
}

#hero .description_container .read_more {
  display: inline-block;
  clear: both;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  line-height: 18px;
  margin-top: 0px;
  padding-bottom: 30px;
  width: 100%;
  padding: 0px;
}

#hero .description_container .read_more img {
  vertical-align: top;
  margin-left: 8px;
}

#hero #hero_canvas {
  margin-top: 56px;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 56px);
}

#hero .image_container .image_border {
  display: none;
}

#hero .image_container img {
  display: none;
}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1) {
    #hero .description_container {
      width: 500px;
      top: calc(50vh + 56px);
      left: 50%;
      transform: translate(-50%, 0%);
    }

    #hero .description_container.invisible {
      opacity: 1;
    }
}


body.mobile #hero {
  padding-top: 74px;
}

body.mobile #hero .navigation {
  display: none;
}

body.mobile #hero .description_container {
  position: inherit;
  width: auto;
  top: auto;
  left: auto;
}

body.mobile #hero .description_container h1 {
  line-height: 50px;
}

body.mobile #hero .description_container h2 {
  line-height: 40px;
}

body.mobile #hero .description_container p br {
  display: none;
}

body.mobile .description_container >p {
  padding: 0;
}

body.mobile #hero .description_container .read_more {
  margin-top: 16px;
  padding-bottom: 24px;
}

body.mobile #hero .image_container img {
  width: 100%;
  height: auto !important;
  display: block;
}

body.mobile #hero .image_container .image_border {
  display: block;
}

body.mobile #hero #hero_canvas {
  display: none;
}


#details .description_container {
  margin-bottom: 32px;
}

#details .detail_section {
  position: relative;
  display: inline-block;
  margin-top: 24px;
}

#details .detail_section .detail_container {
  float: left;
}

#details .detail_section .image_container {
  position: relative;
  width: 80%;
  right: 30%;
}

#details .detail_section:nth-child(even) .image_container {
  left: 30%;
}

#details .detail_section .image_container img {
  width: 100%;
  height: auto;
}

#details .detail_section .text_container {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 60%;
  transform: translate(0%, -50%);
  text-align: left;
}

#details .detail_section .text_container h2, #details .detail_section .text_container p {
  padding-left: 24px;
}

#details .detail_section:nth-child(even) .text_container {
  right: 60%;
  left: auto;
  text-align: right;
}

#details .detail_section:nth-child(even) .text_container h2, #details .detail_section:nth-child(even) .text_container p {
  padding-right: 24px;
  padding-left: 0px;
}

#details .detail_section h2 {
  font-size: 29px;
  margin-top: 12px;
}

@media screen and (max-width: 600px) {
  #details .detail_section .image_container {
    width: 100%;
    right: auto !important;
    left: auto !important;
  }

  #details .detail_section .text_container {
    position: inherit;
    width: 100%;
    top: auto;
    left: auto !important;
    right: auto !important;
    transform: inherit;
    text-align: center !important;
    padding-left: 0px;
  }
}

@media screen and (max-width: 600px) {
  #story .story_section {
    column-count: 1;
    column-gap: 0px;
  }
}

#collection .description_container {
  margin-bottom: 32px;
  margin-top: 40px;
}

#collection .collection_section {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 16px;
}

#collection .slider_outer_container {
  width: 70%;
  float: left;
  position: relative;
}

#collection .slider {
  width: 100%;
}

#collection .slider .slick-prev, #collection .slider .slick-next {
  z-index: 10;
  width: 24px;
  height: 24px;
}

#collection .slider .slick-prev {
  left: 24px !important;
}

#collection .slider .slick-next {
  right: 24px !important;
}

#collection .slider .slick-prev::before, #collection .slider .slick-next::before {
  color: #212529;
  opacity: 0.64;
  font-size: 24px;
}

#collection .slider .slick-slide {
  /* padding: 0px 8px; */
}

#collection .slider .image_container {
  padding-top: 75%;
  position: relative;
}

#collection .image_border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
}

#collection .slider .image_container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
}

#collection .text_container {
  position: absolute;
  width: 30%;
  float: left;
  left: 70%;
  top: 50%;
  transform: translate(0%, -50%);
}

#collection .text_container .text_inner_container {
  padding: 0px 0px 0px 24px;
  text-align: left;
}

#collection .text_container .text_inner_container a {
  float: left;
  margin-top: 8px;
}

#collection .collection_section:nth-child(even) .text_container .text_inner_container {
  padding: 0px 24px 0px 0px;
  text-align: right;
}

#collection .collection_section:nth-child(even) .text_container .text_inner_container a {
  float: right;
}

#collection .collection_section:nth-child(even) .slider_outer_container {
  float: right;
}

#collection .collection_section:nth-child(even) .text_container {
  float: right;
  left: 0%;
}

@media screen and (max-width: 600px) {
  #collection .slider_outer_container {
    width: 100%;
  }

  #collection .text_container {
    position: inherit;
    width: 100%;
    float: left;
    left: auto;
    top: auto;
    transform: inherit;
    padding-top: 8px;
  }

  #collection .text_container .text_inner_container {
    padding: 0px !important;
    text-align: center !important;
  }

  #collection .text_container .text_inner_container h2, #collection .text_container .text_inner_container p {
    text-align: center;
  }

  #collection .text_container .text_inner_container a, #collection .text_container .text_inner_container button {
    float: inherit !important;
  }
}

#story .description_container {
  margin-top: 32px;
}

#story .story_section {
  text-align: left;
  column-count: 2;
  column-gap: 16px;
}

@media screen and (max-width: 600px) {
  #story .story_section {
    column-count: inherit;
    column-gap: inherit;
  }
}

#newsletter {

}

#newsletter .description_container {
  margin-top: 32px;
  margin-bottom: 32px;
}

#newsletter .newsletter_section {
  display: inline-block;
}

#newsletter .newsletter_section {
  display: inline-block;
  margin-top: 16px;
}

#newsletter .detail_section {
  float: left;
}

#newsletter .newsletter_section .detail_section:first-child {
  width: 38%;
}

#newsletter .newsletter_section .detail_section:last-child {
  width: 62%;
}

#newsletter .newsletter_section .detail_section:first-child .padding_container {
  padding-right: 8px;
}

#newsletter .newsletter_section .detail_section:last-child .padding_container {
  padding-left: 8px;
}

#newsletter .newsletter_section .image_container {
  width: 100%;
}

#newsletter .newsletter_section .image_container img {
  width: 100%;
  height: auto;
}

#newsletter .newsletter_section .detail_section h2, #newsletter .newsletter_section .detail_section p {
  text-align: left;
}

#newsletter .newsletter_section .detail_section h2 {
  margin-top: -6px;
}

#newsletter #mc-form {
  text-align: left;
  width: 80%;
  max-width: 320px;
  margin-top: 16px;
}

#newsletter #mc-form input {
  width: 100%;
  line-height: 32px;
  height: 32px;
  padding: 0px;
  clear: both;
  box-shadow: inset 0px 0px 0px 1px #adb5bd;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 1px 12px 0px 12px;
  vertical-align: middle;
  box-sizing: border-box;
}

#newsletter #mc-form button {
  clear: both;
  border: none;
  color: white;
  width: 100%;
  padding: 0px;
  outline: 0;
  font-size: 14px;
  line-height: 34px !important;
  height: 32px;
  background-color: #74b816;
  border-radius: 6px;
  padding: 0px;
  padding: 0px 12px;
  box-shadow: inset 0px 0px 0px 1px #5c940d;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-decoration: none;
  margin-top: 8px;
}

#newsletter #mc-form label {
  width: 100%;
  text-align: center;
  line-height: 18px;
  color: #868e96;
  display: inline-block;
  padding-top: 8px;
}

@media screen and (max-width: 600px) {
  #newsletter .newsletter_section .detail_section {
    width: 100% !important;
    float: none;
  }

  #newsletter .newsletter_section .detail_section:first-child .padding_container {
    padding-right: 0px;
  }

  #newsletter .newsletter_section .detail_section:last-child .padding_container {
    padding-left: 0px;
    margin-top: 32px;
  }

  #newsletter .newsletter_section .detail_section h2, #newsletter .newsletter_section .detail_section p {
    text-align: center;
  }

  #newsletter #mc-form {
    width: 100%;
    max-width: 100%;
  }
}

#faq {
  padding-bottom: 64px;
}

#faq .description_container {
  margin-top: 32px;
  margin-bottom: 24px;
}

#faq .question_section {
  text-align: left;
}

#faq .question_section .question_container h2 {
  font-size: 29px;
  margin-top: 16px;
}

@media screen and (max-width: 600px) {
  #details .detail_section .detail_container {
    width: 100%;
    float: none;
  }

  #details .detail_section .detail_container:first-child .detail_padding_container {
    padding-right: 0px;
  }

  #details .detail_section .detail_container:last-child .detail_padding_container {
    padding-left: 0px;
    margin-top: 32px;
  }
}